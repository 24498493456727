import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import { Element, Link as Ir } from 'react-scroll';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function App() {

  //const [background, setBackground] = useState(false);
  //const [scroll, setScroll] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");
  const [index, setIndex] = useState(0);
  const [verMenu, setVerMenu] = useState('no_ver_menu');

  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  useEffect(() => {
    /*
    window.addEventListener("scroll", (e) => {
      //setBackground(true)
      setScroll(window.scrollY)
    });
    */
    return () => {
      if(window.location.href.substr(0, 5)==='http:' && window.location.href!=='http://localhost:3000/'){
        window.location.href = window.rutas;
      }
      //window.removeEventListener("scroll", (e) => setBackground(false));
    };
  }, [/*background*/]);

  //let suma = 0

  return (
    <div>
      <div className="icono_menu" onClick={() => setVerMenu('ver_menu')}><img src="img/menu.svg" title="Menú" alt="Menú"/></div>
      <div className="cerrar_menu" id={verMenu} onClick={() => setVerMenu('no_ver_menu')}></div>
      <div className="menu" id={window.screen.width>750 ? null : verMenu}>
        <div className="menu_bottom"><div className="max_width_menu">
          <div className="menu_flex">
            {/*
            <div className="logo_flex">
              <img src="img/logo.png" title={window.nombre} alt={window.nombre}/>
            </div>
            */}
            <div className="enlaces">
              <Ir to="inicio" className="mi_enlace">Inicio</Ir>
              <Ir to="libro" className="mi_enlace">Libro</Ir> 
              <Ir to="familia" className="mi_enlace">Familia</Ir>
              <Ir to="autores" className="mi_enlace">Autores</Ir>
              <Ir to="contacto" className="mi_enlace">Contacto</Ir>
              <Ir to="comprar" className="mi_enlace">Comprar</Ir>
              <div className="redes">
                {
                  window._my_redes.map((item, i) => 
                    <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                  )
                }
              </div>
            </div>
          </div>
        </div></div>
      </div>
      <Element name="inicio"></Element>
      <Carousel fade controls={false} interval={5000} pause={false} indicators={false}>
        {
          window._my_carousel.map((item, i) =>
            <Carousel.Item key={i} className="car_img" style={{backgroundImage: `url(${item.foto})`}}>
              <div className="car_fondo">
                <div>
                  {
                    item.modo>0 ?
                      <>
                        {item.titulo ? <h8>{item.texto}</h8> : null}
                        {item.texto ? <h9>{item.titulo}</h9> : null}
                      </>
                    :
                      <>
                        {item.texto ? <h9>{item.texto}</h9> : null}
                        {item.titulo ? <h8>{item.titulo}</h8> : null}
                      </>
                  }
                  {item.www ? <a href={item.www} className="car_url" target="new">{item.www_decir}</a> : null}
                  {item.ir ? <div><div class="baja"></div><Ir to={item.ir} className="boton">{item.ir_decir}</Ir></div> : null}
                </div>
              </div>
            </Carousel.Item>
          )
        }
      </Carousel>
      <div id="redes">
        <div className="redes">
          {
            window._my_redes.map((item, i) => 
              <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
            )
          }
        </div>
      </div>
      <Element name="libro"></Element>
      <div className="fondo_nosotros">
        <div className="max_width padding_width">
          <div className="flex_between alinear">
            <div className="nosotros_texto" data-aos="fade-right">
              {/*<h11>Libro</h11>*/}
              <iframe style={{marginBottom:10}} width="100%" height="315" src="https://www.youtube.com/embed/Yc6pxodeZrU?si=pw5jZid7pSsjfVGx" title="Llano Norte Tráiler" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <h12>“Por un rebozo me fui a quedar...”</h12>
              <div className="justificar">
                Fueron las palabras de la joven Mari antes de 
                quedar comprometida con Francisco, sin imaginar 
                que juntos formarían una familia llena de historias 
                de vida por contar.
                <br/>
                <br/>
                Llano Norte es una crónica basada en hechos 
                reales, que cuenta la historia de vida de una 
                familia que pasó de la luz a la oscuridad y de la 
                duda a la continuidad. Es una historia de unión y 
                esfuerzo familiar, donde la vida puso a prueba su 
                voluntad y toda su capacidad resiliente.
              </div>
              <button className="boton" onClick={() => {
                window.open("https://www.facebook.com/libro.llanonorte/")
              }}>
                Conocer más
              </button>
              {/*
              https://www.youtube.com/watch?v=Yc6pxodeZrU
              <br/>
              <h12>¿Porque Nosotros?</h12>
              <ul className="lista">
                <li>Más de {window.hoy.getFullYear()-2010-1} años de experiencia</li>
                <li>Puntualidad y profesionalismo</li>
                <li>Equipo de audio profesional</li>
                <li>Apegados 100% al concepto del evento</li>
                <li>Amplio repertorio musical (cualquier género o época)</li>
                <li>Sin mixes pregrabados o con “efectitos”</li>
              </ul>
              */}
            </div>
            <div className="nosotros_img" data-aos="fade-left">
              <div className="box_imagen">
                <img src="img/libro.jpg" title={window.nombre} alt={window.nombre}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Element name="familia"></Element>
      <div className="fondo_servicios">
        <div className="max_width padding_width">
          <center>
            {/*<h11>Familia</h11>*/}
            <h14>¿Quién es la familia García Hernández?</h14>
            <br/>
            Originarios de Zimapán, un pueblo minero del estado de Hidalgo, Amalia Hernández Vega y 
            Francisco García Cantera se casaron en 1947, y juntos formaron una familia de 12 hijos: Elena, 
            Melitón, Anastacio, Salvador, Andrés, Luz María, Martha, Francisco, Arturo, Enrique, Rafael y 
            Verónica son los protagonistas que dan vida a esta historia.
          </center>
          {/*
          <div className="servicios margin_servicios">
            {
              window._my_servicios.map((item, i) => 
                <div className="my_servicio" data-aos="fade-down" key={i}>
                  <div className="box_servicio">
                    <div className="caja_servicio">
                      <div className="servicio_relative">
                        <img src={item.foto} title={item.titulo} alt={item.titulo}/>  
                        <div className="servicio_contrato">
                          <a href={`${window.whats_contratar}%20${item.titulo}`} target="new">Contratar</a>
                        </div>
                      </div>
                      <div className="servicio_info">
                        <center>
                          <h15>{item.titulo}</h15>
                          <br/>
                          ({item.leyenda})
                        </center>
                        <ul className="lista_servicios">
                          {
                            item.lista.slice(0, 5).map((lista, n) =>
                              <li key={n}>{lista}</li>
                            )
                          }
                          <li>y más...</li>
                        </ul>
                        <div className="precio_servicio">Desde <h16>{window.moneda.format((item.costo))}</h16></div>
                        <div className="servicio_mas" onClick={() => {
                          setModalServicios(true)
                          setServicio(item.id)
                        }}>
                          Ver más detalles
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div id="cl"></div>
          */}
        </div>
      </div>
      <div className="galeria" >
        {
          window._my_galeria.map((item, i) =>
            <div key={i} className="my_galeria" onClick={() => {
              setModal(true)
              setIndex(item.id)
            }}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  {item.fecha ? <h13>{item.fecha}</h13> : null}
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div id="cl"></div>
      <Element name="autores"></Element>
      <div className="fondo_nosotros">
        <div className="max_width padding_width">
          <div className="flex_between alinear">
            <div className="nosotros_img" data-aos="fade-right">
              <div className="box_imagen">
                <img src="img/autores.jpeg" title={window.nombre} alt={window.nombre}/>
              </div>
            </div>
            <div className="nosotros_texto" data-aos="fade-left">
              {/*<h11>Autores</h11>*/}
              <h12>Sobre los autores</h12>
              <div className="justificar">
                Ángel y Moisés fueron los encargados de recopilar cada una de las historias que Llano Norte tiene para ti.
                <br/><br/>
                ¡Conoce más sobre ellos visitando sus perfiles!
              </div>
              <button className="boton" onClick={() => {
                window.open("https://www.linkedin.com/in/angelgarhz/")
              }}>
                Ángel
              </button>
              <button className="boton espacio_boton" onClick={() => {
                window.open("https://www.linkedin.com/in/moisesjourno/")
              }}>
                Moisés
              </button>
            </div>
          </div>
        </div>
      </div>
      <Element name="comprar"></Element>
      <div className="fondo_comprar">
        <div className="back_fondo">
          <div className="max_width padding_width">
            <div className="titulo_comprar">
              {/*<h11>Comprar</h11>*/}
              Adquiere Llano Norte a través de tu plataforma favorita
              <h14>Y disfruta esta historia inspiradora</h14>
            </div>
            {
              window._my_comprar.length>0 ?
                <div className="flex_comprar">
                  {
                    window._my_comprar.map((item, i) =>
                      <a href={item.link} target="new" key={i}>
                        <div className="img_comprar hvr-push">
                          <img src="img/libro.jpg" title={item.tienda} alt={item.tienda}/>
                          <div className="div_comprar">
                            <img src={item.img} title={item.tienda} alt={item.tienda} className="img_tienda"/>
                          </div>
                        </div>
                        <div id="cl"/>
                        <button className="boton">
                          Comprar
                        </button>
                      </a>
                    )
                  }
                </div>
              :
                null
            }
          </div>
        </div>
      </div>
      <Element name="contacto"></Element>
      <div className="fondo_nosotros">
        <div className="max_width padding_width">
          <center>
            {/*<h11>Contacto</h11>*/}
            <h12>Contactanos para más información</h12>
          </center>
          <form data-aos="fade-down">
            <div className="campos_formulario">
              <input type="text" name="nombre" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre:" value={nombre} required/>
              <input type="text" name="correo" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo:" value={correo} required/>
              <input type="text" name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="Teléfono:" value={telefono} required/>
            </div>
            <textarea name="mensaje" id="area" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje:" value={mensaje} required/>
            {/*
            <div className="datos"><div className={datos===true ? 'datos_hover' : null} onClick={() => setDatos(!datos)}>+ Datos del evento</div></div>
            {
              datos===true ?
                <div className="mi_evento">
                  <div className="campos_formulario">
                    <div>
                      Fecha:
                      <input type="date" name="fecha" onChange={(e) => setFecha(e.target.value)} placeholder="dd/mm/aaaa" value={fecha}/>
                    </div>
                    <div>
                      Hora:
                      <input type="time" name="hora" onChange={(e) => setHora(e.target.value)} placeholder="00:00" value={hora}/>
                    </div>
                    <div>
                      Duración (hrs):
                      <select name="duracion" onChange={(e) => setDuracion(e.target.value)}>
                        <option value="" selected={duracion==="" ? true : false}></option>
                        {
                          window._my_duracion.map((item, i) =>
                            <option value={item} key={i} selected={item===duracion ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="campos_formulario">
                    <div>
                      Estado:
                      <select name="estado" onChange={(e) => setEstado(e.target.value)}>
                        <option value="" selected={duracion==="" ? true : false}></option>
                        {
                          window._my_estado.map((item, i) =>
                            <option value={item.estado} key={i} selected={item.estado===estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Municipio / Delegación:
                      <select name="municipio" onChange={(e) => setMunicipio(e.target.value)}>
                        <option value="" selected={municipio==="" ? true : false}></option>
                        {
                          window._my_estado.filter(p => p.estado===estado).map(esta =>
                            esta.municipios.map((item, i) =>
                              <option value={item} key={i} selected={item===municipio ? true : false}>{item}</option>
                            )
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Código postal:
                      <input type="number" name="cp" onChange={(e) => setCP(e.target.value)} placeholder="Solo número" value={cp}/>
                    </div>
                  </div>
                  <div className="campos_formulario_dos">
                    <div>
                      Tipo de servicio:
                      <select name="tipo" onChange={(e) => setTipo(e.target.value)}>
                        <option value="" selected={tipo==="" ? true : false}></option>
                        {
                          window._my_tipo.map((item, i) =>
                            <option value={item} key={i} selected={item===tipo ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Número aproximado de personas:
                      <select name="personas" onChange={(e) => setPersonas(e.target.value)}>
                        <option value="" selected={personas==="" ? true : false}></option>
                        {
                          window._my_personas.map((item, i) =>
                            <option value={item} key={i} selected={item===personas ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                </div>
              :
                null
            }
            */}
            <center><button onClick={(e) => {
              e.preventDefault();
              if(nombre===''){
                alerta('error','Ingresa tu nombre completo');
              }else if(correo===''){
                alerta('error','Ingresa tu correo electrónico');
              }else if(window.validarMail(correo)===false){
                alerta('error','Ingrese un correo electrónico valido');
              }else if(mensaje===''){
                alerta('error','Escribe un mensaje');
              }else if(telefono===''){
                alerta('error','Ingresa un número telefónico');
              }else{
                window.H5_loading.show();
                var params = new URLSearchParams();
                params.append('nombre', nombre);
                params.append('correo', correo);
                params.append('mensaje', mensaje);
                params.append('telefono', telefono);
                
                Axios.post(`${window.envia}`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',response.data.mensaje)
                      setNombre("");
                      setCorreo("");
                      setTelefono("");
                      setMensaje("");
                    }else{
                      alerta('error',response.data.mensaje)
                    }
                  })
                  .catch(error => {
                    //window.alerta('error','LOLERO'+error);
                    console.log(error)
                  })
                  .then(function() {
                    window.H5_loading.hide();
                  })
              }
            }}>Enviar mensaje</button></center>
          </form>
        </div>
      </div>
      <div className="pie">
        <div className="max_width padding_pie">
          <center>
            <b>© COPYRIGHT, 2023 derechos reservados.</b>
            <br/>
            Todos los derechos reservados. No se permite la reproducción total o parcial de esta obra, ni su incorporación a un sistema informático, ni su transmisión en cualquier forma o por cualquier medio (electrónico, mecánico, fotocopia, grabación u otros) sin autorización previa y por escrito de los titulares del copyright. La infracción de dichos derechos puede constituir un delito contra la propiedad intelectual.
          </center>
          <div className="linea_delgada"/>
          <div className="mi_pie alinear">
            <div className="flex_start alinear">
              <Ir to="inicio" className="subir">➤</Ir>
              {window.nombre} © {window.ano}
            </div>
            <div>
              <div className="flex_end alinear">
                <a href={window.whats} target="new" className="flex_tel alinear">
                  <img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/>
                  {window.telefono}
                </a>
                <a href={`mailto:${window.correo}`} target="new" className="flex_tel alinear">
                  <img src="img/con_3.svg" title="Teléfono" alt="Teléfono"/>
                  {window.correo}
                </a>
              </div>
              <div className="para_aviso">
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("preguntas")
                }}>Preguntas frecuentes</div>
                |
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("aviso")
                }}>Aviso de privacidad</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <a href={window.whats} target="new" className="whats">
        <img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/>
      </a>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false)
        }}
        size="lg"
        className="my-modal"
        centered
      >
        <Modal.Body >
          <Carousel className="slider_modal" defaultActiveIndex={index} interval={1500}>
            {
              window._my_galeria.map((item, i) =>
                <Carousel.Item key={i}>
                  <img src={item.foto} alt="Slider"/>
                </Carousel.Item>
              )
            }
          </Carousel>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Aviso de privacidad</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: window._my_aviso }} className="at_j"/>
              </Modal.Body>
            </>
          : servicio==='preguntas' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Preguntas frecuentes</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion defaultActiveKey="0">
                  {
                    window._my_preguntas.map((item, i) =>
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{item.id}. {item.pregunta}</Accordion.Header>
                        <Accordion.Body>
                          {item.respuesta}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Modal.Body>
            </>
          :
            null
        }
      </Modal>
    </div>
  );
}

export default App;
